import React, {useEffect, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import axios from "axios";
import {baseURL} from "../../utils/constant";
import {checkData} from "../../helper";
import {Link} from "@reach/router";
import Loader from "../components/Loader";

function Pressgrid(props) {
    const [isLoaded, setIsLoaded] = useState(false);
    const [userData, setUserData] = useState([]);
    const getGiHubUserWithAxios = async () => {
        setIsLoaded(true);
        const response = await axios.get(`${baseURL}/press-release`);
        setUserData(response.data.data);
        setIsLoaded(false);
    };
    useEffect(() => {
        getGiHubUserWithAxios();
    }, []);

    return (
        <>
            {isLoaded ?
                <Loader/>
                :
                <div className="press_section">
                    <Container>
                        <Row>
                            <Col xs={12} md={12} lg={12} xl={12} xxl={12}>
                                <div className="example" data-text="Popular topics">
                                    <h2>Popular topics</h2>
                                </div>
                                <div className="press_grid">
                                    {userData?.map((item, i) => (
                                        <div className="blog_item" key={i}>
                                            <div className="content_blog">
                                                <h4>
                                                    {checkData(item, 'company')}
                                                </h4>
                                                <p>{checkData(item, 'title')}</p>
                                                <Link to={checkData(item, 'url')}>Read More</Link>
                                            </div>

                                        </div>
                                    ))}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            }

        </>
    );
}

export default Pressgrid;